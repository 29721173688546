var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "wizard-container" },
          [
            _vm.isDevelopment && false
              ? _c(
                  "code",
                  {
                    staticStyle: {
                      float: "left",
                      position: "absolute",
                      left: "calc(50% - (650px / 2))",
                      bottom: "-2.65rem",
                      padding: ".5rem",
                      display: "flex",
                      "z-index": "10000",
                      "max-width": "650px",
                    },
                  },
                  [_vm._v(" Hash: " + _vm._s(_vm.currentHash) + " ")]
                )
              : _vm._e(),
            _c("div", { staticClass: "wizard-header" }, [
              _c("div", { staticClass: "bar" }),
              _c("div", { staticClass: "slide" }, [
                _c(
                  "div",
                  {
                    staticClass: "circle",
                    attrs: {
                      "is-active": _vm.slide === 0,
                      "is-completed": _vm.slide > 0,
                    },
                  },
                  [_vm._v("1")]
                ),
                _c("span", [_vm._v("INTRODUCTION")]),
              ]),
              _vm.isOnline && false
                ? _c("div", { staticClass: "slide" }, [
                    _c(
                      "div",
                      {
                        staticClass: "circle",
                        attrs: {
                          "is-active": _vm.slide === 1,
                          "is-completed": _vm.slide > 1,
                        },
                      },
                      [_vm._v("2")]
                    ),
                    _c("span", [_vm._v("BANK INFORMATION")]),
                  ])
                : _vm._e(),
              _vm.isOnline && false
                ? _c("div", { staticClass: "slide" }, [
                    _c(
                      "div",
                      {
                        staticClass: "circle",
                        attrs: {
                          "is-active": _vm.slide === 2,
                          "is-completed": _vm.slide > 2,
                        },
                      },
                      [_vm._v("3")]
                    ),
                    _c("span", [_vm._v("CONTACTS")]),
                  ])
                : _vm._e(),
              _vm.isOffline
                ? _c("div", { staticClass: "slide" }, [
                    _c(
                      "div",
                      {
                        staticClass: "circle",
                        attrs: {
                          "is-active": _vm.slide === 1,
                          "is-completed": _vm.slide > 1,
                        },
                      },
                      [_vm._v("2")]
                    ),
                    _c("span", [_vm._v("DOWNLOAD/UPLOAD")]),
                  ])
                : _vm._e(),
              _vm.isOnline
                ? _c("div", { staticClass: "slide" }, [
                    _c(
                      "div",
                      {
                        staticClass: "circle",
                        attrs: {
                          "is-active": _vm.slide === 1,
                          "is-completed": _vm.slide > 1,
                        },
                      },
                      [_vm._v("2")]
                    ),
                    _c("span", [_vm._v("BASIC INFORMATION")]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "slide" }, [
                _c(
                  "div",
                  {
                    staticClass: "circle",
                    attrs: {
                      "is-active": _vm.slide === 2,
                      "is-completed": _vm.slide > 2,
                    },
                  },
                  [_vm._v("3")]
                ),
                _c("span", [_vm._v("REVIEW")]),
              ]),
              _c("div", { staticClass: "slide" }, [
                _c(
                  "div",
                  {
                    staticClass: "circle",
                    attrs: {
                      "is-active": _vm.slide === 3,
                      "is-completed": _vm.slide > 3,
                    },
                  },
                  [_vm._v("4")]
                ),
                _c("span", [_vm._v("FINISH")]),
              ]),
            ]),
            !_vm.isLastSlide()
              ? _c("div", { staticClass: "wizard-actions" }, [
                  _vm.slide > 0 && _vm.slide < 4
                    ? _c(
                        "button",
                        {
                          staticStyle: { position: "absolute", left: "0" },
                          on: {
                            click: function ($event) {
                              _vm.slide--
                            },
                          },
                        },
                        [_vm._v(" Previous Slide ")]
                      )
                    : _vm._e(),
                  _vm.slide < _vm.numSlides
                    ? _c(
                        "button",
                        {
                          staticClass: "is-accent",
                          attrs: {
                            disabled:
                              (!_vm.currentSlideHasReviewed &&
                                _vm.slide !== 0 &&
                                !_vm.isOffline) ||
                              _vm.isLocked,
                          },
                          on: { click: _vm.progressWizard },
                        },
                        [_vm._v(" Next Slide ")]
                      )
                    : _vm._e(),
                  _vm.slide < _vm.numSlides - 1 && _vm.slide > 0 && _vm.isOnline
                    ? _c(
                        "button",
                        {
                          staticClass: "is-default",
                          attrs: { disabled: !_vm.hasChanged },
                          on: { click: _vm.saveForLater },
                        },
                        [_vm._v(" Save For Later ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "wizard-content",
                staticStyle: {
                  "max-height": "calc(100vh - 150px)",
                  height: "100%",
                  overflow: "scroll",
                },
              },
              [
                _vm.slide === 0
                  ? _c("IntroductionSlide", {
                      attrs: {
                        reportType: _vm.reportType,
                        questionnaire: _vm.questionnaire,
                      },
                      on: {
                        completed: _vm.completed,
                        "process-type-changed": _vm.onReportTypeChange,
                      },
                    })
                  : _vm._e(),
                _vm.isOnline && _vm.slide === 1 && false
                  ? _c("BankInformationSlide", {
                      attrs: {
                        questionnaire: _vm.questionnaire,
                        hash: _vm.currentHash,
                      },
                      on: {
                        completed: _vm.completed,
                        "has-reviewed": function ($event) {
                          return (_vm.currentSlideHasReviewed = $event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.isOnline && _vm.slide === 2 && false
                  ? _c("ContactsSlide", {
                      attrs: {
                        questionnaire: _vm.questionnaire,
                        hash: _vm.$sha256(_vm.questionnaire.contacts),
                      },
                      on: {
                        completed: _vm.completed,
                        reset: _vm.reset,
                        "has-reviewed": function ($event) {
                          return (_vm.currentSlideHasReviewed = $event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.isOnline && _vm.slide === 1
                  ? _c("BasicInformationSlide", {
                      attrs: {
                        participantsHash: _vm.participantsHash,
                        questionnaire: _vm.questionnaire,
                        hash: _vm.$sha256(_vm.questionnaire.participants),
                      },
                      on: {
                        completed: _vm.completed,
                        reset: _vm.reset,
                        "import-participants": _vm.importParticipants,
                        toggleLock: _vm.logToggleLock,
                        "validate-data": _vm.validateParticipants,
                        "has-reviewed": function ($event) {
                          return (_vm.currentSlideHasReviewed = $event)
                        },
                      },
                    })
                  : _vm._e(),
                (_vm.isOnline && _vm.slide === 2) ||
                (_vm.isOffline && _vm.slide === 2)
                  ? _c("ReviewSlide", {
                      attrs: {
                        questionnaire: _vm.questionnaire,
                        hash: _vm.currentHash,
                      },
                      on: {
                        completed: _vm.completed,
                        "has-reviewed": function ($event) {
                          return (_vm.currentSlideHasReviewed = $event)
                        },
                        toggleLock: _vm.logToggleLock,
                      },
                    })
                  : _vm._e(),
                _vm.slide === 3 && _vm.questionnaire
                  ? _c("ThankYouSlide", {
                      attrs: { hash: _vm.currentHash },
                      on: {
                        "finish-annual-questionnaire": function (e) {
                          return _vm.$emit("finish-annual-questionnaire", e)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.isOffline && _vm.slide === 1
                  ? _c("UploadSlide", {
                      attrs: {
                        hasUploaded: _vm.hasUploaded,
                        upload: _vm.upload,
                        download: _vm.download,
                        questionnaire: _vm.questionnaire,
                        hash: _vm.currentHash,
                      },
                      on: {
                        completed: _vm.completed,
                        "has-reviewed": function ($event) {
                          return (_vm.currentSlideHasReviewed = $event)
                        },
                        upload: _vm.upload,
                        toggleLock: _vm.logToggleLock,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._l(_vm.formErrors, function (error) {
              return _c("span", { key: error }, [
                _c("p", { staticStyle: { color: "red" } }, [
                  _vm._v(_vm._s(error)),
                ]),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.isLoading ? _c("div", [_c("Loading")], 1) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }