var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        display: "flex",
        "justify-content": "center",
        "align-items": "center",
      },
    },
    [
      _c("div", { staticStyle: { "max-width": "550px" } }, [
        _c(
          "h1",
          { staticStyle: { "text-align": "center", "font-size": "3rem" } },
          [_vm._v("Thank You!")]
        ),
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v(
            " Thank you for completing the Annual Questionnaire. A copy of your response will be added to your account and accessible to authorized users. "
          ),
        ]),
        _vm._m(0),
        _c("br"),
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v(
            " You will be redirected to your dashboard in " +
              _vm._s(_vm.secondsUntilRedirect) +
              " seconds. "
          ),
        ]),
        _vm._m(1),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("br"),
      _vm._v(
        " If you need to make any further adjustments to your Annual Questionnaire,  please email bccclientservices@bcc-usa.com or call 800-781-2099. "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "text-align": "center", "margin-top": "3rem" } },
      [
        _vm._v(" Click "),
        _c("a", { attrs: { href: "/" } }, [_vm._v("here")]),
        _vm._v(" to proceed. "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }