var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "columns",
        staticStyle: { width: "100%", padding: "0px!important" },
      },
      [
        _c(
          "div",
          {
            staticClass: "column",
            staticStyle: { width: "100%", padding: "0px!important" },
          },
          [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass: "columns",
                staticStyle: {
                  width: "100%",
                  padding: "0px 0px 7px 0px!important",
                  gap: ".5rem",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "column flex-container flex-full",
                    staticStyle: {
                      "flex-wrap": "wrap",
                      display: "flex !important",
                      "flex-direction": "row!important",
                      gap: "0.5rem !important",
                      padding: "0px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "field", staticStyle: { width: "100%" } },
                      [
                        _vm._m(1),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientAddress,
                                  expression: "input.clientAddress",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: { value: _vm.input.clientAddress },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientAddress",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors["clientAddress"],
                                    expression:
                                      "validationErrors['clientAddress']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.validationErrors["clientAddress"])
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { "max-width": "calc(50%) !important" },
                      },
                      [
                        _vm._m(2),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientAddressCity,
                                  expression: "input.clientAddressCity",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: { value: _vm.input.clientAddressCity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientAddressCity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors["clientAddressCity"],
                                    expression:
                                      "validationErrors['clientAddressCity']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationErrors["clientAddressCity"]
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { "max-width": "calc(15%) !important" },
                      },
                      [
                        _vm._m(3),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientAddressState,
                                  expression: "input.clientAddressState",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: { value: _vm.input.clientAddressState },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientAddressState",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors[
                                        "clientAddressState"
                                      ],
                                    expression:
                                      "validationErrors['clientAddressState']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationErrors["clientAddressState"]
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { "max-width": "calc(30%) !important" },
                      },
                      [
                        _vm._m(4),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientAddressPostal,
                                  expression: "input.clientAddressPostal",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: {
                                value: _vm.input.clientAddressPostal,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientAddressPostal",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors[
                                        "clientAddressPostal"
                                      ],
                                    expression:
                                      "validationErrors['clientAddressPostal']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationErrors["clientAddressPostal"]
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "column flex-container flex-full",
                    staticStyle: {
                      "flex-wrap": "wrap",
                      "flex-direction": "row!important",
                      display: "flex",
                      gap: "0.5rem !important",
                      padding: "0px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "field", staticStyle: { width: "100%" } },
                      [
                        _vm._m(5),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientMailingAddress,
                                  expression: "input.clientMailingAddress",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: {
                                value: _vm.input.clientMailingAddress,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientMailingAddress",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors[
                                        "clientMailingAddress"
                                      ],
                                    expression:
                                      "validationErrors['clientMailingAddress']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationErrors["clientMailingAddress"]
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { "max-width": "calc(50%) !important" },
                      },
                      [
                        _vm._m(6),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientMailingAddressCity,
                                  expression: "input.clientMailingAddressCity",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: {
                                value: _vm.input.clientMailingAddressCity,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientMailingAddressCity",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors[
                                        "clientMailingAddressCity"
                                      ],
                                    expression:
                                      "validationErrors['clientMailingAddressCity']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationErrors[
                                      "clientMailingAddressCity"
                                    ]
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { "max-width": "calc(15%) !important" },
                      },
                      [
                        _vm._m(7),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientMailingAddressState,
                                  expression: "input.clientMailingAddressState",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: {
                                value: _vm.input.clientMailingAddressState,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientMailingAddressState",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors[
                                        "clientMailingAddressState"
                                      ],
                                    expression:
                                      "validationErrors['clientMailingAddressState']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationErrors[
                                      "clientMailingAddressState"
                                    ]
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { "max-width": "calc(30%) !important" },
                      },
                      [
                        _vm._m(8),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientMailingAddressPostal,
                                  expression:
                                    "input.clientMailingAddressPostal",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "legal address",
                                "validator-requirement": "'required'",
                              },
                              domProps: {
                                value: _vm.input.clientMailingAddressPostal,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientMailingAddressPostal",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.validationErrors[
                                        "clientMailingAddressPostal"
                                      ],
                                    expression:
                                      "validationErrors['clientMailingAddressPostal']",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.validationErrors[
                                      "clientMailingAddressPostal"
                                    ]
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "column flex-container flex-full",
                    staticStyle: {
                      "flex-wrap": "wrap",
                      gap: "0.5rem !important",
                      padding: "0px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: {
                          width: "100%",
                          padding: "0 !important",
                          margin: "0px !important",
                        },
                      },
                      [
                        _vm._m(9),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientPhone,
                                  expression: "input.clientPhone",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "business phone",
                                "validator-requirement": "'required'",
                              },
                              domProps: { value: _vm.input.clientPhone },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientPhone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("business phone"),
                                    expression: "errors.has('business phone')",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.errors.first("business phone"))
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "field",
                        staticStyle: { width: "100%", "margin-top": ".6rem" },
                      },
                      [
                        _vm._m(10),
                        _c(
                          "div",
                          {
                            staticClass: "control-center",
                            staticStyle: { width: "100%" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.input.clientFax,
                                  expression: "input.clientFax",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                name: "fax number",
                                "validator-requirement": "'required'",
                              },
                              domProps: { value: _vm.input.clientFax },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.input,
                                    "clientFax",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("fax number"),
                                    expression: "errors.has('fax number')",
                                  },
                                ],
                                staticClass: "help is-danger",
                              },
                              [_vm._v(_vm._s(_vm.errors.first("fax number")))]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]
    ),
    _c("div", { staticStyle: { width: "100%", "margin-top": "3rem" } }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.hasReviewed,
            expression: "hasReviewed",
          },
        ],
        staticStyle: { "margin-right": ".75rem" },
        attrs: {
          type: "checkbox",
          name: "confirmed",
          disabled: !_vm.inputsAreValid,
        },
        domProps: {
          checked: Array.isArray(_vm.hasReviewed)
            ? _vm._i(_vm.hasReviewed, null) > -1
            : _vm.hasReviewed,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.hasReviewed,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.hasReviewed = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.hasReviewed = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.hasReviewed = $$c
            }
          },
        },
      }),
      _c(
        "label",
        {
          attrs: { for: "confirmed", disabled: !_vm.inputsAreValid },
          on: {
            click: function ($event) {
              _vm.inputsAreValid ? (_vm.hasReviewed = !_vm.hasReviewed) : null
            },
          },
        },
        [_vm._v("I have reviewed the above information.")]
      ),
      _vm.questionnaire.jsonReviewedBy &&
      _vm.questionnaire.jsonReviewedBy.bankInformation
        ? _c(
            "p",
            {
              staticStyle: { color: "red" },
              attrs: { disabled: !_vm.inputsAreValid },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.questionnaire.jsonReviewedBy.bankInformation) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "subheading",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "margin-bottom": "1.5rem",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("b", [_vm._v("Bank Contact Information")]),
        _c("br"),
        _vm._v(" Please review and correct the information as needed. "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Legal Address "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" City "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" ST "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Postal Code "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Mailing Address "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" City "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" ST "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Postal Code "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Main Phone "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label text-left is-size-7" }, [
      _vm._v(" Main Fax "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }