var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        height: "100%",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "flex-start",
        "align-items": "center",
      },
    },
    [
      _c("div", { staticClass: "wizard-grid" }, [
        _vm._m(0),
        _vm._m(1),
        _c("div", { staticClass: "wizard-grid-item" }, [
          _c(
            "div",
            {
              staticClass: "wizard-actions",
              staticStyle: { "padding-top": "1.5rem" },
            },
            [
              _c("button", { on: { click: _vm.handleDownload } }, [
                _vm._v("Download"),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._m(2),
      _c("div", { staticClass: "wizard-grid" }, [
        _vm._m(3),
        _vm._m(4),
        _c("div", { staticClass: "wizard-grid-item" }, [
          _c(
            "div",
            {
              staticClass: "wizard-actions",
              staticStyle: { "padding-top": "1.5rem" },
            },
            [
              _c(
                "button",
                {
                  attrs: { disabled: !_vm.hasDownloaded },
                  on: { click: _vm.upload },
                },
                [_vm._v("Upload")]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "wizard-grid-item",
        staticStyle: { "max-width": "200px", "justify-content": "flex-start" },
      },
      [_c("b", [_vm._v("Step 1:")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wizard-grid-item" }, [
      _c("b", [_vm._v("Download Questionnaire")]),
      _c("p", { staticStyle: { "padding-top": "0.5rem" } }, [
        _vm._v(" Click the "),
        _c("strong", [_vm._v("Download")]),
        _vm._v(" button to Download the Questionnaire. "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "wizard-grid", staticStyle: { height: "90px" } },
      [
        _c(
          "div",
          {
            staticClass: "wizard-grid-item",
            staticStyle: { "justify-content": "flex-start" },
          },
          [_c("b", [_vm._v("Step 2:")])]
        ),
        _c("div", { staticClass: "wizard-grid-item" }, [
          _c("b", [_vm._v("Complete Questionnaire")]),
          _c("p", [
            _vm._v(
              " Complete the Questionnaire using Microsoft Excel on your local computer. "
            ),
          ]),
          _c("br"),
        ]),
        _c("div", {
          staticClass: "wizard-grid-item",
          staticStyle: { width: "150px" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "wizard-grid-item",
        staticStyle: { "max-width": "200px", "justify-content": "flex-start" },
      },
      [_c("b", [_vm._v("Step 3:")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wizard-grid-item" }, [
      _c("b", [_vm._v("Upload Questionnaire")]),
      _c("p", { staticStyle: { "padding-top": "0.5rem" } }, [
        _vm._v(" Click the "),
        _c("strong", [_vm._v("Upload")]),
        _vm._v(" button to submit your Questionnaire response. "),
        _c("br"),
        _c("br"),
        _vm._v(" or "),
        _c("br"),
        _c("br"),
        _vm._v(
          " E-mail your completed Questionnaire to BCCclientservices@bcc-usa.com. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }