var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLoading || _vm.questionnaireHash === "",
              expression: "isLoading || questionnaireHash === ''",
            },
          ],
        },
        [_c("Loading")],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading,
              expression: "!isLoading",
            },
          ],
          staticStyle: {
            overflow: "scroll",
            "margin-top": "9.25rem",
            padding: "0rem",
          },
          attrs: { id: "body-content-area-all" },
        },
        [
          _c("annual-questionnaire-wizard", {
            attrs: {
              reportType: _vm.reportType,
              questionnaire: _vm.questionnaire,
              hasChanged: _vm.hasChanged,
              currentHash: _vm.currentHash,
              participantsHash: _vm.participantsHash,
            },
            on: {
              "report-type-changed": _vm.onReportTypeChanged,
              "import-participants": _vm.onImportParticipants,
              "save-for-later": _vm.saveForLater,
              reset: _vm.getQuestionnaire,
              "finish-annual-questionnaire": _vm.finishAnnualQuestionnaire,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }