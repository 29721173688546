var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard-slide" }, [
    _c("p", [_vm._v(" " + _vm._s(_vm.questionnaire.portalMessage) + " ")]),
    _c("br"),
    _c("div", { staticClass: "wizard-grid" }, [
      _vm._m(0),
      _c("div", { staticClass: "wizard-grid-item" }, [
        _c("p", [
          _vm._v(
            " Please review and complete the Annual Questionnaire in its entirety. The information you provide should be as of " +
              _vm._s(_vm.formattedAsOfDate) +
              ". The completed Questionnaire should be completed and returned no later than " +
              _vm._s(_vm.formatteDueDate) +
              ". "
          ),
        ]),
        _vm._m(1),
        _c("p", [
          _vm._v(
            " Plan participants and all insureds contact information, such as contact number and email, retirement or termination status, and salary and compensation information. "
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.processType,
                expression: "processType",
              },
            ],
            attrs: { type: "radio", name: "process", value: "online" },
            domProps: { checked: _vm._q(_vm.processType, "online") },
            on: {
              change: function ($event) {
                _vm.processType = "online"
              },
            },
          }),
          _c(
            "label",
            {
              attrs: { for: "process" },
              on: {
                click: function ($event) {
                  _vm.processType = "online"
                },
              },
            },
            [_vm._v("Complete Questionnaire online")]
          ),
        ]),
        _vm._m(2),
        _c("div", { staticClass: "control" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.processType,
                expression: "processType",
              },
            ],
            attrs: { type: "radio", name: "process", value: "offline" },
            domProps: { checked: _vm._q(_vm.processType, "offline") },
            on: {
              change: function ($event) {
                _vm.processType = "offline"
              },
            },
          }),
          _c(
            "label",
            {
              attrs: { for: "process" },
              on: {
                click: function ($event) {
                  _vm.processType = "offline"
                },
              },
            },
            [_vm._v("Download Questionnaire to complete manually")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "wizard-grid-item",
        staticStyle: { "max-width": "200px" },
      },
      [_c("b", [_vm._v("Instructions")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _vm._v(" Before you begin please consider gathering the following:"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          padding: "1rem",
          "padding-left": "2rem",
          "margin-bottom": "0rem",
        },
      },
      [
        _c("b", { staticStyle: { "margin-right": ".5rem" } }, [
          _vm._v("Note:"),
        ]),
        _vm._v(
          " You will have the option to Save For Later once you begin the process. The Questionnaire does not have to be completed all in one sitting. "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }