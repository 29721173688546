var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "columns",
      staticStyle: {
        width: "100%",
        padding: "0px !important",
        overflow: "scroll",
      },
    },
    [
      !_vm.isLoading
        ? _c(
            "div",
            {
              staticClass: "column",
              staticStyle: { width: "100%", padding: "0px !important" },
            },
            [
              _c("input", {
                staticStyle: { display: "none" },
                attrs: { type: "file", id: "csvFile", accept: ".csv" },
              }),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-bottom": "2rem",
                    "max-width": "75%",
                    "font-weight": "bold",
                    "font-size": ".8rem",
                    color: "#3e5881",
                  },
                },
                [
                  _vm._v(
                    ' You can download a CSV template to import your contacts. Please click "Export" to download the template. Once you have filled out the template, you can import it by clicking "Import". '
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "subheading",
                  staticStyle: {
                    padding: "0px 0px 7px 0px",
                    "margin-bottom": "1.5rem",
                    "border-bottom": "solid 1px lightgray",
                    position: "relative",
                  },
                },
                [
                  _c("b", [_vm._v("Participant Information")]),
                  _c("div", { staticClass: "show-deleted" }, [
                    _vm.hasDeletedContacts
                      ? _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                _vm.showDeletedContacts =
                                  !_vm.showDeletedContacts
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.showDeletedContacts ? "Hide" : "Show"
                                ) +
                                " Deleted Participants "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "button is-secondary is-small",
                        staticStyle: { "margin-left": "1rem" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.uploadParticipantCSV()
                          },
                        },
                      },
                      [_vm._v(" Import ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button is-secondary is-small",
                        staticStyle: { "margin-left": "1rem" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadParticipantCSV()
                          },
                        },
                      },
                      [_vm._v(" Export ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "button is-warning is-small",
                        staticStyle: { "margin-left": "1rem" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.resetContactTable()
                          },
                        },
                      },
                      [_vm._v(" Reset Participants ")]
                    ),
                  ]),
                  _c("br"),
                  _vm._v(
                    " Please review and correct the information as needed. "
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "columns",
                  staticStyle: {
                    width: "100%",
                    padding: "0px 0px 7px 0px !important",
                    gap: "0.5rem",
                  },
                },
                [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "table" },
                      [
                        _c("DataTable", {
                          attrs: {
                            "header-fields": _vm.participantFields,
                            tableData: _vm.filteredContacts,
                            "is-loading": _vm.isLoading,
                            css: _vm.datatableCss,
                            limit: 100,
                            listenOn: "participants-imported",
                            "not-found-msg": "No contacts found",
                          },
                          on: { "on-add-row": _vm.addContactRow },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function (props) {
                                  return _c("div", {}, [
                                    props &&
                                    props.rowData &&
                                    props.rowData.isDeleted === 0
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "button is-light",
                                            staticStyle: {
                                              "max-width": "50px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteRow(
                                                  props.rowData
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("-")]
                                        )
                                      : _vm._e(),
                                    props &&
                                    props.rowData &&
                                    props.rowData.isDeleted !== 0
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "button is-light",
                                            staticStyle: {
                                              "max-width": "50px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.unDeleted(
                                                  props.rowData
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("+")]
                                        )
                                      : _vm._e(),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            3521187833
                          ),
                        }),
                        _vm._l(_vm.errorsForm, function (error) {
                          return _c("span", { key: error.message }, [
                            _c("p", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(error.message)),
                            ]),
                          ])
                        }),
                        _c("br"),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "margin-top": "3rem",
                    "margin-bottom": "3rem",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-start",
                        "align-items": "center",
                      },
                      attrs: { disabled: _vm.errors.length > 0 },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.hasReviewed,
                            expression: "hasReviewed",
                          },
                        ],
                        staticStyle: { "margin-right": "0.75rem" },
                        attrs: {
                          disabled: _vm.errors.length > 0,
                          type: "checkbox",
                          name: "confirmed",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.hasReviewed)
                            ? _vm._i(_vm.hasReviewed, null) > -1
                            : _vm.hasReviewed,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.hasReviewed,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.hasReviewed = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.hasReviewed = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.hasReviewed = $$c
                            }
                          },
                        },
                      }),
                      _c(
                        "label",
                        {
                          attrs: { for: "confirmed", disabled: !_vm.isValid },
                          on: {
                            click: function ($event) {
                              !_vm.isValid
                                ? null
                                : (_vm.hasReviewed = !_vm.hasReviewed)
                            },
                          },
                        },
                        [_vm._v("I have reviewed the above information.")]
                      ),
                    ]
                  ),
                  _vm.questionnaire.jsonReviewedBy &&
                  _vm.questionnaire.jsonReviewedBy.bankInformation
                    ? _c(
                        "p",
                        {
                          staticStyle: { color: "red" },
                          attrs: { disabled: _vm.errors.length > 0 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.questionnaire.jsonReviewedBy.bankInformation
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _c("div", [_c("Loading")], 1),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }